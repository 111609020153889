import { AutoblockSituationFilterOptions } from 'domains/AutoblockProgress';
import { ClientDayOfWeekType } from 'domains/Client';
import { ClientFileType } from 'domains/ClientFile';
import {
  AddAutoblockErrorCodes,
  AutoblockChannels,
  DdcGroup,
  StrategyType,
} from 'domains/DDCAutoblockAdd';
import { DDCsEditExceptionErrors } from 'domains/DDCEditData';
import { DeliveryWindowOrigin, DeliveryWindowsModalError } from 'domains/DeliveryWindow';
import { RuleDownloadFileSubType } from 'domains/RuleFile';
import MessageMap from 'i18n/i18n';

const en: MessageMap = {
  TEST: {
    TEST_TRANSLATE: 'Translate {value}',
    TEST_LOREM: 'Lorem',
    TEST_IPSUM: 'Ipsum',
    TEST_DOLOR: 'Dolor',
  },
  DayOfWeek: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  DayOfWeekShort: {
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
  },
  DayOfWeekRecurrency: {
    monday: 'Every Monday',
    tuesday: 'Every Tuesday',
    wednesday: 'Every Wednesday',
    thursday: 'Every Thursday',
    friday: 'Every Friday',
    saturday: 'Every Saturday',
    sunday: 'Every Sunday',
  },
  DayOfWeekPreposition: {
    monday: 'on Monday',
    tuesday: 'on Tuesday',
    wednesday: 'on Wednesday',
    thursday: 'on Thursday',
    friday: 'on Friday',
    saturday: 'on Saturday',
    sunday: 'on Sunday',
  },
  General: {
    AND: 'and',
    CANCEL: 'Cancel',
    CLEAR: 'Clear',
    GO_BACK: 'Go back',
    YES: 'Yes',
    NO: 'No',
    OF: 'of',
    NA: 'N/A',
    EMPTY: 'Empty',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    DEFAULT_AUTOCOMPLETE_PLACEHOLDER_MULTIPLE: 'Select options',
    DEFAULT_EMPTY_LIST: 'Nothing to show',
    OPTIONAL: 'Optional',
  },
  VendorSelect: {
    NO_VENDORS: 'No vendors set',
  },
  AppBar: {
    MENU_DDCS_LIST: 'DDCs Management',
    MENU_DELIVERY_WINDOW: 'Delivery Windows',
    MENU_IMPORTS_AND_EXPORTS: 'Imports and Exports',
    MENU_AUTOBLOCK: 'Delivery Capacity Availability Parameters',
  },
  DataTable: {
    FOOTER_ROW_SELECTED_ONE: '{value} selected Item',
    FOOTER_ROW_SELECTED_MANY: '{value} selected Items',
    PAGINATION_OF: 'of',
    NO_ROWS: 'Nothing to show',
    ONE_ROW_SELECTED: 'selected',
    MULTIPLE_ROWS_SELECTED: 'selected',
    DESELECT_ROWS: 'deselect',
    LOADING_MESSAGE: 'Loading items...',
  },
  Pagination: {
    PAGE_SIZE_OPTION: 'Show {value} items per page',
    OF: 'of',
  },
  DropZone: {
    CONTAINER_TEXT: 'Drag and Drop an CSV File to upload it or click to search',
    FILE_LIMITE_EXCEED: 'Files Limit ({filesLimit}) exceeded',
    PREVIEW: 'Files sent:',
  },
  HexaDocumentUploader: {
    DROPZONE_PLACEHOLDER: 'Drop here your file',
    DROPZONE_ERROR_EMPTY: 'Please, select a valid file',
    DROPZONE_ERROR_VALID_FILE:
      'The file must have the valid format ({format}) and be a maximum of {size}',
    DROPZONE_BUTTON: 'Search',
  },
  DateRange: {
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    DATE_FORMAT: 'MM/dd/yyyy',
  },
  TabNavigation: {
    DCCS_LIST: 'Manage Parameters',
  },
  GenerateDeliveryWindowsButton: {
    BUTTON_TEXT: 'Resend windows',
    MODAL_TITLE: 'Attention!',
    MODAL_TEXT:
      'Generation of delivery windows is recommended only to solve eventual problems. Do you want to proceed?',
    ERROR_MESSAGE: 'Error requesting delivery windows generation',
    SUCCESS_MESSAGE: 'Requested Delivery Window Generation Process is now in progress',
  },
  DDCsList: {
    TITLE: 'DDCs Management',
    BREADCRUMB: 'DDCs Management',
    DATAGRID_COLUMN_DDC_CODE: 'DDC ID',
    DATAGRID_COLUMN_DDC_NAME: 'DDC Name',
    DATAGRID_COLUMN_DDC_STATUS: 'Status',
    DATAGRID_COLUMN_ACTIONS: 'Actions',
    DATAGRID_CELL_ACTIONS_EDIT_TOOLTIP: 'View/Edit',
    DATAGRID_CELL_ACTIONS_HOLIDAY_TOOLTIP: 'Manage Holidays',
    DATAGRID_CELL_ACTIONS_RULES_TOOLTIP: 'Manage Inclusion/Exclusion Rules',
    DATAGRID_CELL_ACTIONS_CNC_TOOLTIP: 'View Click & Collect consume',
    UNABLE_TO_LOAD_LIST: 'Error loading DDCs List',
    EDIT_SELECTION_BUTTON: 'Edit Selection',
    HOLIDAY_SELECTION_BUTTON: 'Manage Holidays',
    RULES_SELECTION_BUTTON: 'Manage Inclusion/Exclusion Rules',
    SEARCH_LABEL: 'Search by ID or Name',
    SEARCH_FILTER_LABEL: 'Filter',
    FILTER_STATUS_ALL: 'All status',
    FILTER_STATUS_ACTIVE: 'Enabled status',
    FILTER_STATUS_INACTIVE: 'Disabled status',
  },
  DDCsListClickAndCollectModal: {
    SUBTITLE: 'Click & Collect',
    DATAGRID_COLUMN_DATE: 'Collect date',
    DATAGRID_COLUMN_CAPACITY: 'Capacity',
    DATAGRID_COLUMN_CONSUME: 'Utilization',
    DATEFORMAT: 'EEE, MMM dd, yyyy',
    DATETIMEFORMAT: 'EEE, MMM dd, yyyy, hh:mm aa',
    UNABLE_TO_LOAD_LIST: 'Error loading Click & Collect list',
    BUTTON_TOOLTIP: 'View Click & Collect consume',
    BUTTON_TOOLTIP_DISABLED: 'These Click & Collect parameters are disabled: {fields}',
    BUTTON_TOOLTIP_FIELD_ACTIVE: '"Day of the week"',
    BUTTON_TOOLTIP_FIELD_FULL_ORDER_CAPACITY: '"Full order capacity"',
  },
  DDCsAutoblockAddModal: {
    TITLE: 'Add an autoblock',
    DDC_AUTOCOMPLETE_LABEL: 'Select distribution centers',
    PERIOD_TITLE: 'Define period',
    PERIOD_HINT:
      'Select one or multiple days of the week, or define a specific date to apply the autoblock.',
    RADIO_DAYS_OF_WEEK_LABEL: 'Recurrent days of the week',
    RADIO_SPECIFIC_DATE_LABEL: 'Specific date',
    SPECIFIC_DATE_FORMAT: 'MM/dd/yyyy',
    LIMIT_TITLE: "Define DC's visit limit number",
    LIMIT_HINT: 'If the limit is reached, the delivery window will be blocked.',
    LIMIT_INPUT_PLACEHOLDER: 'Enter limit number',
    CONFIRM_BUTTON: 'Add autoblock',
    TOAST_SUCCESS: 'Autoblock successfully added',
    TOAST_FAILURE: 'Error creating Autoblock',
    TOAST_MIXED: 'One or more autoblocks could not be created',
  },
  DDCsAutoblockRemoveModal: {
    MODAL_TITLE: 'Delete autoblock?',
    MODAL_TEXT:
      'The delivery window will stop following the autoblock definitions. If this autoblock has already been applied, it will be reverted.',
    BUTTON_CONFIRM: 'Delete',
    BUTTON_CANCEL: 'Go back',
    TOAST_SUCCESS: 'Autoblock deleted',
    TOAST_FAILURE: 'Error deleting autoblock',
  },
  DDCsListHolidayConfirmModal: {
    TITLE: 'Holiday Management',
    CONFIRM_BUTTON: 'Continue',
    CANCEL_BUTTON: 'Cancel',
    UPPER_TEXT:
      'It is not possible to manage the holidays of the Units below, as they do not have the Location information:',
    LOWER_TEXT: 'Do you want to continue?',
  },
  DDCsEdit: {
    TITLE: 'Edit Data',
    BREADCRUMB: 'Edit parameters',
    SUBMIT_BUTTON: 'Confirm parameters',
    DDCS_LIST_TITLE: 'Selected Units',
    DDCS_LIST_VIEW_MORE: 'View More',
    DDCS_LIST_HIDE: 'Hide',
    VISIT_DAY: 'Visit Day',
    HOLIDAY_DATE: 'Holiday Date',
    DELIVERY_DAY: 'Delivery Date',
    FORM_PARAMETERS_TITLE: 'General Parameters',
    FORM_GENERATE_DELIVERY_WINDOW: 'Generate Delivery Windows',
    FORM_TIMEZONE: 'Time zone',
    FORM_DAYS_AFTER_VISIT_DAY: 'Automatic Rule - Calendar Days',
    FORM_DAYS_OF_WEEK: 'Automatic Rule - Weekdays',
    FORM_HOLIDAY_DELIVERY_DAY: 'Holiday Delivery Day',
    FORM_HOLIDAY_DELIVERY_DAY_ZERO: 'Do not replace Holiday',
    FORM_WINDOW_RANGE: 'Number of days to generate delivery windows',
    FORM_EXPIRATION_DELIVERY_DAY_IS: 'When the delivery is on',
    FORM_EXPIRATION_EXPIRATION_DAY_IS: 'When the expiration is on',
    FORM_EXPIRATION_DAY: 'Expiration Day',
    FORM_EXPIRATION_TIME: 'Expiration Time (local)',
    FORM_EXPIRATION_USE_FOR_ALL: 'Apply for all',
    FORM_EXPIRATION_LABEL: 'Expiration Date and Time:',
    FORM_WORKING_DAYS_LABEL: 'Working days',
    FORM_IS_SUNDAY_WORKED_DAY_LABEL: 'Consider sunday as worked day',
    FORM_WORKING_DAYS_HINT: 'Expiration date will consider these days as valid for expiration',
    FORM_CLICK_AND_COLLECT_PARAMETERS_LABEL: 'Click & Collect - Basic Parameters',
    FORM_CLICK_AND_COLLECT_CONSUME_LABEL: 'Click & Collect - Days of Week',
    FORM_CLICK_AND_COLLECT_ENABLED_LABEL: 'Click & Collect enabled',
    FORM_CLICK_AND_COLLECT_RANGE_LABEL: 'Days limit',
    FORM_CLICK_AND_COLLECT_EXPIRATION_LABEL: 'Expiration Time (D-1)',
    FORM_CLICK_AND_COLLECT_DAY_ENABLED_LABEL: 'Enabled day',
    FORM_CLICK_AND_COLLECT_DAY_START_LABEL: 'Start',
    FORM_CLICK_AND_COLLECT_DAY_END_LABEL: 'End',
    FORM_CLICK_AND_COLLECT_TIME_ERROR: 'Start time must be smaller than end time',
    FORM_CLICK_AND_COLLECT_DISABLE_ERROR:
      "Once enabled, Click and Collect must remain enabled for at least one day of week.\nTo completely disable it, it will be necessary to change the Click and Collect settings on this Unit's Clients",
    FORM_CLICK_AND_COLLECT_FULLORDERCAPACITY_ENABLED_LABEL: 'Manage Click & Collect Capacity',
    FORM_CLICK_AND_COLLECT_FULLORDERCAPACITY_VALUE_LABEL: 'Click & Collect Capacity',
    UNABLE_TO_EDIT_CDDS: 'Error Editing Units',
    UNABLE_TO_GET_DDC_INFO: 'Error fetching unit data',
    SAVING_SUCCESS: 'Parameters saved successfully',
    SAVING_ERROR: 'One or more units could not be saved.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.CncActiveDaysRequired.toUpperCase()}`]:
      'Must have at least one active Click and Collect day.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidDayOfWeekSequence.toUpperCase()}`]:
      'Invalid Days of Week.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidExpirationTime.toUpperCase()}`]:
      'Invalid Expiration Time.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidFullOrderCapacityActive.toUpperCase()}`]:
      'Invalid Full Order Capacity Values.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidFullOrderCapacityValue.toUpperCase()}`]:
      'Invalid Full Order Capacity Values.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.ErrorGetDdcBeesInformation.toUpperCase()}`]:
      "Error retreiving DC's information (BEES).",
    [`SAVING_ERROR_${DDCsEditExceptionErrors.OtherError.toUpperCase()}`]:
      'Please, try again later.',
    FORM_GENERATE_DELIVERY_WINDOW_NULL: 'Do not change',
    FORM_GENERATE_DELIVERY_WINDOW_ENABLED: 'Enabled',
    FORM_GENERATE_DELIVERY_WINDOW_DISABLED: 'Disabled',
    WORKING_DAYS_ALERT:
      'Delivery windows and expiration days of delivery window only happen on days selected as workdays. Changing this field will recalculate the delivery windows for the entire distribution center.',
    TOGGLE_BUTTON: 'Edit Field',
    TOGGLE_TUTORIAL_1:
      'Enable or disable the fields you want to change or not by clicking the buttons “',
    TOGGLE_TUTORIAL_2:
      '”.\nEnabled fields with no value will be saved as empty. Disabled fields will not change.',
  },
  DDCsEditV2: {
    TITLE: 'Edit settings',
    BREADCRUMB: 'Edit settings',
    DELIVERY_DAY: 'Delivery day',
    SAVE_BUTTON: 'Save changes',
    SELECTED_DDCS_TITLE: 'Selected DDCs',
    SELECTED_DDCS_TEXT:
      'Saved settings will be used to create delivery windows for the selected DDCs.',
    TAB_DELIVERY_WINDOWS: 'Delivery windows',
    TAB_DDC_OPERATION: 'DDC operation',
    TAB_CLICK_AND_COLLECT: 'Click & Collect',
    TAB_IDENTIFIERS: 'Identifiers',
    PARAMETERS_TITLE: 'Parameters',
    FIELD_GENERATE_DELIVERY_WINDOWS_LABEL: 'Generate delivery windows',
    FIELD_WINDOW_RANGE_LABEL: 'Window range',
    FIELD_WINDOW_RANGE_HINT:
      'The orders can be delivered within 1 to 60 days after the order placement date.',
    HOLIDAY_TITLE: 'Holiday strategy',
    HOLIDAY_TEXT: 'Set the behavior when a delivery window falls on a holiday.',
    BUTTON_MANAGE_HOLIDAYS: 'Manage holidays',
    FIELD_HOLIDAY_DELIVERY_DAY: 'Behavior',
    FIELD_HOLIDAY_DELIVERY_DAY_ZERO: 'Do not replace Holiday',
    HOLIDAY_DATE: 'Holiday Date',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT_ZERO:
      'When the delivery window falls on a holiday, the order delivery date remains the same.',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT:
      'When the delivery window falls on a holiday, the order delivery date should be updated to ',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT_POSITIVE: ' day(s) after the holiday.',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT_NEGATIVE: ' day(s) before the holiday.',
    AUTOMATIC_RULES_TITLE: 'Automatic rules',
    AUTOMATIC_RULES_TEXT:
      'These rules only apply when the DDC default option is selected on the Edit clients page.',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_LABEL: 'By BDR visits',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_HINT: 'Create delivery windows determined after a BDR visit.',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_OPTION_SINGULAR: '1 day after',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_OPTION_PLURAL: '{value} days after',
    FIELD_AUTOMATIC_RULE_DAY_OF_WEEK_LABEL: 'Fixed days',
    FIELD_AUTOMATIC_RULE_DAY_OF_WEEK_HINT:
      'Create recurring delivery windows within the window range for specific days of the week.',
    TIMEZONE_TITLE: 'Parameters',
    FIELD_TIMEZONE_LABEL: 'Time zone',
    FIELD_TIMEZONE_HINT: 'Select the DDCs operations time zone.',
    EXPIRATION_TITLE: 'Default expiration behavior',
    EXPIRATION_TEXT:
      'Choose default order deadline based on delivery and working days. These rules can be overwritten by exceptions set up in the platform.',
    FIELD_WORKING_DAYS_LABEL: 'Working days',
    FIELD_WORKING_DAYS_HINT: 'Select which days of the week the DDCs are working.',
    EXPIRATION_DAYS_TITLE: 'Expiration days',
    EXPIRATION_DAYS_TEXT:
      'Select the calculation rule to find the expiration day corresponding to each delivery day.',
    FIELD_DELIVERY_DAY_LABEL: 'Delivery day',
    FIELD_EXPIRATION_DAY_LABEL: 'Calculation rule',
    FIELD_CALCULATED_EXPIRATION_DAY_LABEL: 'Expiration day',
    EXPIRATION_TIMES_TITLE: 'Order placement deadlines',
    EXPIRATION_TIMES_TEXT:
      'Set the deadline for placing an order corresponding to each expiration day.',
    FIELD_EXPIRATION_TIME_LABEL: 'Deadline (DDC time)',
    CNC_PARAMETERS_TITLE: 'Parameters',
    CNC_EXPIRATION_TIME_LABEL: 'Order placement deadline (D-1)',
    CNC_EXPIRATION_TIME_HINT: 'Order deadline on the day before.',
    CNC_RANGE_LABEL: 'Window range',
    CNC_RANGE_HINT: 'Set the Click & Collect window range within 1 to 60 days.',
    CNC_FULL_ORDER_CAPACITY_ACTIVE_LABEL: 'Manage capacity',
    CNC_FULL_ORDER_CAPACITY_ACTIVE_HINT: 'Enable Click & Collect capacity management',
    CNC_FULL_ORDER_CAPACITY_VALUE_LABEL: 'Amount of orders',
    CNC_FULL_ORDER_CAPACITY_VALUE_HINT: 'Limit the daily amount of orders allowed.',
    CNC_DAYS_OF_WEEK_TITLE: 'Time range setup',
    CNC_DAYS_OF_WEEK_TEXT: 'Set the Click & Collect start and end time for each day of the week.',
    CNC_START_TIME_LABEL: 'Start',
    CNC_END_TIME_LABEL: 'End',
    CNC_DAY_OF_WEEK_DISABLED: 'Collect is disabled',
    CLICK_AND_COLLECT_CAPACITY_TITLE: 'Current capacity',
    IDENTIFIERS_TITLE: 'Parameters',
    FIELD_DDC_NAME_LABEL: 'DDC name',
    FIELD_DDC_NAME_PLACEHOLDER: 'Enter DDC name',
    FIELD_BEES_ID_LABEL: 'BEES ID',
    FIELD_BEES_ID_HINT: 'This ID identifies the DDC within BEES ecosystem.',
    FIELD_BEES_ID_PLACEHOLDER: 'Enter BEES ID',
    FIELD_CITY_CODE_LABEL: 'City code',
    FIELD_CITY_CODE_HINT: "Same as city ID, it's used for holiday integration by some zones.",
    FIELD_CITY_CODE_PLACEHOLDER: 'Enter City Code',
    FIELD_FEDERATED_UNIT_LABEL: 'State',
    FIELD_FEDERATED_UNIT_HINT: "Same as state ID, it's used for holiday integration by some zones.",
    FIELD_FEDERATED_UNIT_PLACEHOLDER: 'Enter State',
  },
  DDCsEditModalConfirmV2: {
    MODAL_TITLE: 'Save changes?',
    MODAL_TEXT: 'The following settings will be applied for all of the selected DDCs.',
    BUTTON_CANCEL: 'No, go back',
    BUTTON_SUBMIT: 'Yes, save',
    FIELD_EMPTY: 'Empty',
    DDCS_LIST: 'DDCs',
    VISIT_DAY: 'BDR visit day',
    DELIVERY_DAY: 'Delivery day',
    CLICK_AND_COLLECT: 'Click & collect',
    CLICK_AND_COLLECT_START_TIME: 'Start',
    CLICK_AND_COLLECT_END_TIME: 'End',
    DELIVERY_DAYS_AFTER_VISIT_DAY: 'Automatic rule - By BDR visits',
    DELIVERY_DAYS_OF_WEEK: 'Automatic rule - Fixed days',
    HOLIDAY_DELIVERY_DAY: 'Holiday strategy - Behavior',
    LEAVE_MODAL_TITLE: 'Leave without saving?',
    LEAVE_MODAL_TEXT: 'If you leave without saving, you will lose all changes.',
    LEAVE_MODAL_CANCEL: 'No, cancel',
    LEAVE_MODAL_CONFIRM: 'Yes, leave',
  },
  DDCsEditModalConfirm: {
    TITLE: 'Parameter Change',
    CONFIRM_BUTTON: 'Confirm',
    CANCEL_BUTTON: 'Cancel',
    MAIN_TEXT: 'The following changes will be made. do you wish to continue?',
    DDCS_LIST_TITLE: 'Changed untis:',
    FIELDS_LIST_TITLE: 'Parameters to save:',
    FIELD_EXPIRATION: 'Expiration',
    FIELD_EMPTY: 'Empty',
  },
  DDCsAutoblock: {
    TABTITLE: 'Availability parameters list',
    BREADCRUMB: 'Delivery Capacity Availability Parameters',
    TITLE: 'Delivery Capacity Availability Parameters',
    SELECTED_DDCS_LABEL: 'Selected DCs',
    SEARCH_FIELD_PLACEHOLDER: 'Search by DC code or name',
    ADD_AUTOBLOCK_BUTTON: 'Add an Availability Parameter',
    DATAGRID_COLUMN_DDC_CODE: 'Code',
    DATAGRID_COLUMN_DDC_NAME: 'Distribution center',
    DATAGRID_COLUMN_CREATED_AT: 'Created on',
    DATAGRID_COLUMN_CREATED_BY: 'Created by',
    DATAGRID_COLUMN_PERIOD: 'Period',
    DATAGRID_COLUMN_REASON: 'Reason',
    DATAGRID_COLUMN_LIMIT: 'Limit number',
    DATAGRID_COLUMN_STRATEGY: 'Strategy',
    DATAGRID_COLUMN_ACTIONS: 'Actions',
    DATAGRID_COLUMN_ACTION_DELETE: 'Remove Availability Parameter',
    DATAGRID_COLUMN_ACTION_EDIT: 'Edit Availability Parameter',
    DATEFORMAT: 'MMM dd, yyyy',
    DATAGRID_CELL_VISIT_LIMIT: '{value} visits',
    DATAGRID_CELL_STRATEGIES_PLURAL: '{value} defined',
    DATAGRID_CELL_STRATEGIES_SINGULAR: '{value} defined',
    UNABLE_TO_LOAD_LIST: 'Error loading Availability Parameters list. Please, try again later.',
  },
  DDCsHoliday: {
    TITLE: 'Manage Holidays',
    BREADCRUMB: 'Manage Holidays',
    SUBMIT_BUTTON: 'Save Settings',
    DDCS_SIDELIST_TITLE: 'Selected Units',
    UNABLE_TO_EDIT_CDDS: 'Error saving Settings',
    TABLE_CELL_STATUS: 'Status',
    TABLE_CELL_ACTIONS: 'Actions',
    TABLE_CELL_DDC_CODE: 'Unit Code',
    TABLE_CELL_DDC_NAME: 'Unit',
    TABLE_CELL_DATE: 'Date',
    TABLE_CELL_WORKED_HOLIDAY: 'Worked Holiday',
    TABLE_CELL_TYPE: 'Holiday Type',
    TABLE_NO_DATA: 'No Holiday Found',
    FILTER_TITLE: 'Search:',
    FILTER_START_DATE: 'Start Date',
    FILTER_END_DATE: 'End Date',
    FILTER_SUBMIT: 'Search',
    FILTER_DATE_FORMAT: 'MM/dd/yyyy',
    ADD_NEW_TITLE: 'Add Holiday:',
    ADD_NEW_DATE_FORMAT: 'MM/dd/yyyy',
    ADD_NEW_FIELD_DATE: 'Holiday Date',
    ADD_NEW_FIELD_DDC: 'Unit',
    ADD_NEW_FIELD_DDC_ALL: 'Select All',
    ADD_NEW_SUBMIT: 'Add',
    ADD_ERROR_DUPLICATED: 'One or more holidays were not added because they are duplicates',
    STATUS_EDITING: 'Edited Holiday',
    STATUS_DELETING: 'Holiday Marked for Exclusion',
    STATUS_INSERTING: 'New Holiday',
    STATUS_PRISTINE: 'Saved Holiday',
    TYPE_LOCAL: 'Local',
    TYPE_STATE: 'State',
    TYPE_NATIONAL: 'National',
    ERROR_DUPLICATED_DATE: 'There is already a holiday on this date for this Unit',
    DELETE_LABEL: 'Mark for Removal',
    DELETE_LABEL_UNDO: 'Unmark for removal',
    DELETE_LABEL_NEW: 'Remove new',
    UNABLE_TO_SAVE_ADD_EDIT: 'Error inserting/editing holidays',
    UNABLE_TO_SAVE_DELETE: 'Error removing holidays',
    SAVING_ERROR_EARLIER: 'Holiday is earlier than today',
    SAVING_ERROR_ALREADYEXISTING: 'Duplicated Holiday',
    SAVING_ERROR_MISSINGFEDERATEDUNIT: 'Holiday without federated unit',
    SAVING_ERROR_NOTFOUND: 'Holiday not found',
    UNABLE_TO_SAVE_DATA: 'Error saving entered, edited and/or removed holidays. Please try again.',
    SAVING_ERROR_WARNING_1:
      'One or more holidays could not be saved. See details by hovering over the “',
    SAVING_ERROR_WARNING_2: '” icon on the highlighted lines',
  },
  DDCsHolidayModalConfirm: {
    TITLE: 'Confirm Holiday changes',
    CONFIRM_BUTTON: 'Confirm',
    CANCEL_BUTTON: 'Cancel',
    MAIN_TEXT:
      'This action cannot be undone, and holidays will apply to delivery windows. Commit the changes?',
  },
  DDCMissingFields: {
    WARNING_MESSAGE:
      'The Unit does not have {fields} information to activate the delivery window. Unit information must be regularized for activation.',
    FIELD_CITYCODE: 'Locality',
    FIELD_TIMEZONEID: 'Time zone',
    FIELD_GENERATEDELIVERYWINDOW: 'Enabled Delivery Windows Generation',
    FIELD_DAYSOFWEEKSETTINGS: 'Expiration Date/Time',
    FIELD_ACTIVE: 'Active Unit',
    DELIVERYDAYS_MESSAGE:
      'This unit has no automatic rule. Delivery windows will not be generated for clients who do not have their own delivery week configured.',
  },
  DDCsPagination: {
    ROWS_PER_PAGE_LABEL: 'per page',
  },
  DDCsRules: {
    TITLE: 'Edit Inclusion/Exclusion Rules',
    BREADCRUMB: 'Edit Inclusion/Exclusion Rules',
    OWNER_LIST_TITLE: 'Selected Units',
    SUBMIT_BUTTON: 'Confirm Parameters',
    OWNER_LIST_VIEW_MORE: 'View More',
    OWNER_LIST_HIDE: 'Hide',
  },
  ClientsList: {
    TITLE: 'Clients List',
    BREADCRUMB: 'Delivery Windows',
    EDIT_SELECTION_BUTTON: 'Edit Selection',
    RULES_SELECTION_BUTTON: 'Manage Inclusion/Exclusion Rules',
    UNABLE_TO_LOAD_LIST: 'Error fetching Clients List',
    DATAGRID_COLUMN_DDC_CODE: 'Unit Code',
    DATAGRID_COLUMN_CODE: 'Client Code',
    DATAGRID_COLUMN_DOCUMENT: 'Document',
    DATAGRID_COLUMN_NAME: 'Name',
    DATAGRID_COLUMN_CLICKANDCOLLECTACTIVE: 'Click&Collect',
    DATAGRID_COLUMN_EXCEPTION: 'Exception',
    DATAGRID_ICON_FREE: 'Free',
    DATAGRID_ICON_PAID: 'Flexible\nDelivery Fee: {addAmount}\nMinimum order: {minValue}',
    DATAGRID_CELL_ACTIONS_EDIT_TOOLTIP: 'View/Edit',
    DATAGRID_CELL_ACTIONS_RULES_TOOLTIP: 'Manage Inclusion/Exclusion Rules',
    DATAGRID_CELL_ACTIONS_DELIVERY_WINDOWS_TOOLTIP: 'View Delivery Windows',
    LINK_UPLOAD_CSV: 'Clients CSV Upload/Download',
    SEARCH_LABEL: 'Search',
    SEARCH_FILTER_LABEL: 'Filter',
    SEARCH_BUTTON: 'Search',
    SEARCH_FIELD_DDC_CODE: 'Unit Code',
    SEARCH_FIELD_DDC_NAME: 'Unit Name',
    SEARCH_FIELD_CLIENT_CODE: 'Client Code',
    SEARCH_FIELD_CLIENT_DOCUMENT: 'Document',
    SEARCH_FIELD_CLIENT_NAME: 'Client Name',
  },
  ViewDeliveryWindowsModal: {
    TITLE: 'Delivery Windows',
    CLOSE_BUTTON: 'Close',
    TABLE_CELL_DELIVERY_WINDOW_ID: 'Delivery Window ID',
    TABLE_CELL_DATE: 'Date',
    TABLE_CELL_EXPIRATION_DATE: 'Expiration Date',
    TABLE_CELL_UPDATE_DATE: 'Update Date',
    TABLE_CELL_FLEXIBLE_DELIVERY: 'Flexible Delivery',
    TABLE_NO_DATA: 'No delivery windows found for this Client',
    UNABLE_TO_GET_DATA: 'Error fetching delivery windows',
  },
  ViewDeliveryWindowsModalV2: {
    SUBTITLE: 'Delivery windows',
    TAB_ACTIVES: 'Active',
    TAB_DELETED: 'Deleted',
    SEARCH_PLACEHOLDER: 'Search by ID',
    COLUMN_ID: 'ID',
    COLUMN_DELIVERY_DATE: 'Delivery date',
    COLUMN_DELIVERY_TYPE: 'Delivery type',
    COLUMN_GENERATED_BY: 'Generated from',
    COLUMN_DELETED_BY: 'Deleted from',
    COLUMN_EXPIRATION_DATE: 'Expiration date',
    COLUMN_UPDATE_DATE: 'Update date',
    [`DELIVERY_TYPE_${ClientDayOfWeekType.flex}`]: 'Flex',
    [`DELIVERY_TYPE_${ClientDayOfWeekType.free}`]: 'Free',
    DELIVERY_TYPE_FLEX_MESSAGE: 'Fee: {addAmount} • MOV: {minValue}',
    DELIVERY_TYPE_FREE_MESSAGE: 'No fee and MOV',
    [`ORIGIN_${DeliveryWindowOrigin.autoblock}`]: 'Delivery Capacity',
    [`ORIGIN_${DeliveryWindowOrigin.daysOfWeek}`]: 'Day of the week',
    [`ORIGIN_${DeliveryWindowOrigin.holiday}`]: 'Holiday',
    [`ORIGIN_${DeliveryWindowOrigin.inclusionRule}`]: 'Inclusion rule',
    [`ORIGIN_${DeliveryWindowOrigin.exclusionRule}`]: 'Exclusion rule',
    [`ORIGIN_${DeliveryWindowOrigin.removedDayOfWeek}`]: 'Day of the week removed',
    [`ORIGIN_${DeliveryWindowOrigin.replacedDueHoliday}`]: 'Replaced due holiday',
    [`ORIGIN_${DeliveryWindowOrigin.holidayDeleted}`]: 'Holiday deleted',
    [`ORIGIN_${DeliveryWindowOrigin.ruleDeleted}`]: 'Rule deleted',
    [`ORIGIN_${DeliveryWindowOrigin.ddcDisabled}`]: 'DC Disabled',
    [`ORIGIN_${DeliveryWindowOrigin.outOfWindowRange}`]: 'Out of window range',
    [`ORIGIN_${DeliveryWindowOrigin.integrationIssue}`]: 'Integration issue',
    [`ORIGIN_${DeliveryWindowOrigin.outOfHolidayCalculation}`]: 'Holiday',
    [`ORIGIN_${DeliveryWindowOrigin.autoblockStrategyRemoved}`]: 'Delivery capacity removed',
    [`ORIGIN_${DeliveryWindowOrigin.autoblockDeleted}`]: 'Delivery capacity removed',
    [`ORIGIN_${DeliveryWindowOrigin.capacityStrategy}`]: 'Delivery capacity',
    [`ORIGIN_${DeliveryWindowOrigin.deliveryScheduledIdUpdated}`]: 'ScheduleId updated',
    [`ORIGIN_${DeliveryWindowOrigin.deliveryFrequencyChanged}`]: 'Delivery Frequency changed',
    [`ORIGIN_${DeliveryWindowOrigin.visitDateChanged}`]: 'Visit date changed',
    [`ORIGIN_${DeliveryWindowOrigin.expiredDeliveryWindow}`]: 'Expired delivery window',
    [`ORIGIN_${DeliveryWindowOrigin.unknown}`]: 'Unknown',
    HAS_AUTOBLOCK_CHANGE_TOOLTIP: 'Delivery window changed by a Delivery Capacity Parameter',
    DATEFORMAT: 'EEE, MMM dd, yyyy',
    DATETIMEFORMAT: 'EEE, MMM dd, yyyy, hh:mm aa',
    [`ERROR_${DeliveryWindowsModalError.accountIdNotFound}`]:
      "This customer's contract is not linked to any AccountId, it was not possible to check delivery windows",
    MINVALUE_DIVERGENCE_TOOLTIP:
      'Minimum Order mismatch detected. The value should be {minValueDivergence}. Please wait a few minutes for the data to be integrated or resend the delivery windows for this customer.',
    ADDAMOUNT_DIVERGENCE_TOOLTIP:
      'FEE mismatch detected. The value should be {addAmountDivergence}. Please wait a few minutes for the data to be integrated or resend the delivery windows for this customer.',
    MINVALUE_ADDAMOUNT_DIVERGENCE_TOOLTIP:
      'FEE and Minimum Order mismatch detected. The values should be FEE {addAmountDivergence} and Minimum Order {minValueDivergence}. Please wait a few minutes for the data to be integrated or resend the delivery windows for this customer.',
    EXPIRATIONDATE_DIVERGENCE_TOOLTIP:
      'Expiration date mismatch detected. The value should be {expirationDateDivergence}. Please wait a few minutes for the data to be integrated or resend the delivery windows for this customer.',
  },
  ImportsAndExports: {
    TITLE: 'Imports and Exports',
    BREADCRUMB: 'Imports and Exports',
    TAB_CLIENTS: 'Clients',
    TAB_AUTOMATED_EXCEPTIONS: 'Rules',
    TAB_UPLOAD: 'Manage',
    TAB_DOWNLOAD: 'Package data requests',
    TAB_CLIENTS_UPLOAD: 'Upload Clients Base',
    TAB_CLIENTS_DOWNLOAD: 'Download Clients Base',
    TAB_AUTOMATED_EXCEPTIONS_UPLOAD: 'Upload Rules Base',
    TAB_AUTOMATED_EXCEPTIONS_DOWNLOAD: 'Download Rules Base',
    UPLOAD_FILTER_SEARCH_PLACEHOLDER: 'Search by file name or user',
    DOWNLOAD_FILTER_SEARCH_PLACEHOLDER: 'Search by user',
    TITLE_CLIENTS_UPLOAD: 'Clients Base Upload History',
    TITLE_CLIENTS_DOWNLOAD: 'Available Clients Base files',
    TITLE_AUTOMATED_EXCEPTIONS_UPLOAD: 'Rule Inclusion/Exclusion Base Upload History',
    TITLE_AUTOMATED_EXCEPTIONS_DOWNLOAD: 'Available Rules Base files',
    LINK_DOWNLOAD_DEFAULT_CLIENTS_FILE: 'Download template',
    LINK_DOWNLOAD_DEFAULT_RULES_FILE: 'Download template',
    LINK_REQUEST_CLIENTS_BASE: 'Request Clients Base',
    LINK_REQUEST_AUTOMATED_EXCEPTIONS_BASE: 'Request Rules Base',
    UNABLE_TO_UPLOAD_CSV: 'Error sending CSV.',
    UPLOAD_SUCCESS: 'File Sent Successfully!',
    REQUEST_CLIENTS_BASE_SUCCESS:
      'The clients base has been requested and will soon be available for download',
    REQUEST_CLIENTS_BASE_ERROR:
      'A request is still processing. Please wait a few minutes and try again',
    REQUEST_AUTOMATED_EXCEPTIONS_BASE_SUCCESS:
      'The rules base has been requested and will soon be available for download',
    REQUEST_AUTOMATED_EXCEPTIONS_BASE_ERROR:
      'A request is still processing. Please wait a few minutes and try again',
    HISTORY_DATAGRID_COLUMN_NAME: 'File',
    HISTORY_DATAGRID_COLUMN_UPLOAD_DATE: 'Uploaded on',
    HISTORY_DATAGRID_COLUMN_LINES: 'Lines',
    HISTORY_DATAGRID_COLUMN_LINES_SUCCESS: 'Success',
    HISTORY_DATAGRID_COLUMN_LINES_FAIL: 'Errors',
    HISTORY_DATAGRID_COLUMN_STATUS: 'Status',
    HISTORY_DATAGRID_COLUMN_USER: 'Uploaded by',
    HISTORY_DATAGRID_ACTION_DOWNLOAD: 'Download File',
    HISTORY_DATAGRID_ACTION_DOWNLOAD_FAIL: 'Download errors',
    UNABLE_TO_LOAD_HISTORY_LIST: 'Error fetching history of CSV Uploads',
    UNABLE_TO_LOAD_DOWNLOAD_BASE_LIST: 'Error fetching the files for download',
    UNABLE_TO_DOWNLOAD_FILE: 'Error downloading file',
    UPLOAD_STATUS_awaitingProcessing: 'Queued',
    UPLOAD_STATUS_processing: 'Processing',
    UPLOAD_STATUS_processed: 'Analyzed',
    UPLOAD_STATUS_error: 'Failed to process',
    UPLOAD_STATUS_invalidFile: 'Invalid file',
    UPLOAD_DATE_FORMAT: 'MMM dd, yyyy, hh:mm bb OOOO',
    UPLOAD_DATE_SENT: 'Sent',
    DOWNLOAD_BASE_DATAGRID_COLUMN_REQUEST_DATE: 'Requested on',
    DOWNLOAD_BASE_DATAGRID_COLUMN_TYPE: 'Type',
    [`DOWNLOAD_BASE_DATAGRID_COLUMN_TYPE_${ClientFileType.downloadClients}`]: 'Clients Base',
    [`DOWNLOAD_BASE_DATAGRID_COLUMN_TYPE_${ClientFileType.downloadClientAudit}`]: 'Audit',
    DOWNLOAD_BASE_DATAGRID_COLUMN_STATUS: 'Status',
    DOWNLOAD_BASE_DATAGRID_COLUMN_USER: 'Requested by',
    DOWNLOAD_BASE_DATAGRID_ACTION_DOWNLOAD: 'Download File',
    DOWNLOAD_BASE_STATUS_awaitingProcessing: 'Queued',
    DOWNLOAD_BASE_STATUS_processing: 'Processing',
    DOWNLOAD_BASE_STATUS_processed: 'Ready for download',
    DOWNLOAD_BASE_STATUS_error: 'Failed to process',
    DOWNLOAD_BASE_DATE_FORMAT: 'MMM dd, yyyy, hh:mm bb OOOO',
    DOWNLOAD_BASE_DATE_SENT: 'Sent',
    UPLOAD_CLIENT_DATE_FIELDS_DENIED:
      'You don\'t have permission to edit "Date" and "Delivery Type". These fields will be ignored when you upload a CSV.',
    UPLOAD_CLIENT_FLEX_FIELDS_DENIED:
      'You don\'t have permission to edit "Min Value" and "Add Amount" fields. These fields will be ignored when you upload a CSV.',
    UPLOAD_CLIENT_CLICK_AND_COLLECT_FIELDS_DENIED:
      'You don\'t have permission to edit "Click & Collect" fields. These fields will be ignored when you upload a CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_DATE_FIELDS_DENIED:
      'You don\'t have permission to edit "Date", "Expiration", and "Delivery Type". These fields will be ignored when you upload a CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_FLEX_FIELDS_DENIED:
      'You don\'t have permission to edit "Min Value" and "Add Amount" fields. These fields will be ignored when you upload a CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_DDC_DENIED:
      "You don't have permission to edit DDC Exceptions. These exceptions will be ignored when you upload a CSV.",
    UPLOAD_AUTOMATED_EXCEPTIONS_CLIENT_DENIED:
      "You don't have permission to edit Client Exceptions. These exceptions will be ignored when you upload a CSV.",
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_REQUEST_DATE: 'Request Date',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_TYPE: 'Type',
    [`DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_TYPE_${RuleDownloadFileSubType.database}`]:
      'Rules Base',
    [`DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_TYPE_${RuleDownloadFileSubType.audit}`]:
      'Audit',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_STATUS: 'Status',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_USER: 'User',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_ACTION_DOWNLOAD: 'Download File',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_awaitingProcessing: 'Queued',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_processing: 'Processing',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_processed: 'Ready for download',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_error: 'Failed to process',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATE_FORMAT: 'MM/dd/yyyy hh:mm:ss bb',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATE_SENT: 'Sent',
    REQUEST_AUDIT_FILE_BUTTON: 'Download single CSV file',
    REQUEST_AUDIT_FILE_SUCCESS:
      'The file has been requested and will soon be available for download',
    REQUEST_AUDIT_FILE_ERROR: 'Error requesting file',
    UPLOAD_MODAL_BUTTON_CLIENTS: 'Upload clients file',
    UPLOAD_MODAL_BUTTON_RULES: 'Upload rules file',
    UPLOAD_MODAL_TITLE: 'Upload CSV file',
    UPLOAD_MODAL_DROPZONE_PLACEHOLDER: 'Drop here your file',
    UPLOAD_MODAL_DROPZONE_BUTTON: 'Search',
    UPLOAD_MODAL_CONFIRM: 'Upload',
  },
  RuleCSVUpload: {
    TITLE: 'Upload rules file',
    BREADCRUMB: 'Upload rules file',
    CARD_TITLE: 'File upload',
    MAIN_LIST_TITLE: 'Download CSV template, review the instructions, and upload your file.',
    MAIN_LIST_ITEM_1: 'Semicolons must be used only as column separators.',
    MAIN_LIST_ITEM_2: 'Check for leading zeros in IDs.',
    MAIN_LIST_ITEM_3: 'Save as CSV format.',
    RULES_TARGET_LABEL: 'Rules target',
    RULES_TARGET_HINT: 'Select the target for your uploaded rules.',
    RULES_TARGET_DDC: 'DDC',
    RULES_TARGET_CLIENT: 'Clients',
    INSTRUCTIONS_TITLE: 'Review file instructions',
    INSTRUCTIONS_ITEM_MOV: 'Minimum_Order_Value',
    INSTRUCTIONS_ITEM_MOV_1: 'Value that must be reached for free delivery.',
    INSTRUCTIONS_ITEM_MOV_2: 'Use a dot as a decimal separator.',
    INSTRUCTIONS_ITEM_FEE: 'Additional_Fee',
    INSTRUCTIONS_ITEM_FEE_1: 'Fee charged when minimum value is not reached.',
    INSTRUCTIONS_ITEM_FEE_2: 'Use a dot as a decimal separator.',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE: 'Delivery_Type',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE_1: 'The delivery type for each rule.',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE_2: 'Enter 0 or NO, 1 or FREE, 2 or FLEX.',
    INSTRUCTIONS_ITEM_RULE_TYPE: 'Rule_Type',
    INSTRUCTIONS_ITEM_RULE_TYPE_1: 'A column to add or delete an inclusion or exclusion rule.',
    INSTRUCTIONS_ITEM_RULE_TYPE_2:
      'Enter 0 or EXCLUSION, 1 or INCLUSION_FREE, 2 or INCLUSION_FLEX, 3 or DELETE_RULE.',
    INSTRUCTIONS_ITEM_EXPIRATION_DATE: 'Expiration_Date',
    INSTRUCTIONS_ITEM_EXPIRATION_DATE_1: 'The date when a rule expires.',
    INSTRUCTIONS_ITEM_EXPIRATION_DATE_2: 'Date format is yyyy-mm-dd.',
    INSTRUCTIONS_ITEM_EXPIRATION_TIME: 'Expiration_Time',
    INSTRUCTIONS_ITEM_EXPIRATION_TIME_1: 'The hour when a rule expires.',
    INSTRUCTIONS_ITEM_EXPIRATION_TIME_2: 'Hour format is hh:mm.',
    TEMPLATE_DOWNLOAD_BUTTON: 'Download Template',
    UPLOAD_HINT:
      'Drag and drop a file from your computer or upload it.\nWe support only CSV files. Maximum file size: 50MB.',
    UPLOAD_BUTTON: 'Upload',
  },
  ClientCSVUpload: {
    TITLE: 'Upload clients file',
    BREADCRUMB: 'Upload clients file',
    CARD_TITLE: 'File upload',
    MAIN_LIST_TITLE: 'Download CSV template, review the instructions, and upload your file.',
    MAIN_LIST_ITEM_1: 'Semicolons must be used only as column separators.',
    MAIN_LIST_ITEM_2: 'Check for leading zeros in IDs.',
    MAIN_LIST_ITEM_3: 'Save as CSV format.',
    INSTRUCTIONS_TITLE: 'Review file instructions',
    INSTRUCTIONS_ITEM_MOV: 'Minimum_Order_Value',
    INSTRUCTIONS_ITEM_MOV_1: 'Value that must be reached for free delivery.',
    INSTRUCTIONS_ITEM_MOV_2: 'Use a dot as a decimal separator.',
    INSTRUCTIONS_ITEM_FEE: 'Additional_Fee',
    INSTRUCTIONS_ITEM_FEE_1: 'Fee charged when minimum value is not reached.',
    INSTRUCTIONS_ITEM_FEE_2: 'Use a dot as a decimal separator.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE: 'Reference_Date',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_1: 'Reference used to calculate the delivery date.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_2: 'Date format is yyyy-mm-dd.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_3:
      'If Delivery_Frequency is 7 or WEEKLY, this field is optional.',
    INSTRUCTIONS_ITEM_DELIVERY_FREQUENCY: 'Delivery_Frequency',
    INSTRUCTIONS_ITEM_DELIVERY_FREQUENCY_1: 'The delivery frequency for each client.',
    INSTRUCTIONS_ITEM_DELIVERY_FREQUENCY_2: 'Enter 7 or WEEKLY, 14 or BIWEEKLY, 28 or MONTHLY.',
    INSTRUCTIONS_ITEM_RESET_TO_DDC_DEFAULT: 'Reset_To_DDC_Default',
    INSTRUCTIONS_ITEM_RESET_TO_DDC_DEFAULT_1: 'Enter YES or NO.',
    INSTRUCTIONS_ITEM_RESET_TO_DDC_DEFAULT_2:
      'If YES, leave Minimum_Order_Value, Additional_Fee, and days of the week blank.',
    INSTRUCTIONS_ITEM_CLICK_AND_COLLECT: 'Click_And_Collect',
    INSTRUCTIONS_ITEM_CLICK_AND_COLLECT_1: 'Allows on-site order collection.',
    INSTRUCTIONS_ITEM_CLICK_AND_COLLECT_2: 'Enter YES or NO.',
    TEMPLATE_DOWNLOAD_BUTTON: 'Download Template',
    UPLOAD_HINT:
      'Drag and drop a file from your computer or upload it.\nWe support only CSV files. Maximum file size: 50MB.',
    UPLOAD_BUTTON: 'Upload',
  },
  ClientUploadGuidelines: {
    LIST_TITLE: 'Rules for a successful upload:',
    LIST_ITEM_1: 'Semicolon is the column separator',
    LIST_ITEM_2: 'Dot is decimal separator',
    LIST_ITEM_3: 'Maximum file size: 50MB',
    LIST_ITEM_4:
      'Do not remove any columns or change the order of the columns in the template file.',
    LIST_ITEM_5: 'When saving the file, select the option "Comma Separated Values (CSV)"',
    LIST_ITEM_6:
      'If you prepared the file from a Delivery Admin client export report, remove the "exception" column before importing the file.',
    LIST_ITEM_7:
      'We recommend formatting the customer identification columns as text so that Excel does not remove leading zeros.',
    LIST_ITEM_8:
      'The fields BaselineDate, DeliveryFrequency, and ReturnToDDCDefault are not mandatory, importing them as blank will not perform any updates on these parameters in the database.',
    LIST_ITEM_9: 'Date format: yyyy-mm-dd',
    LIST_ITEM_10: 'The DeliveryFrequency field only accepts the values: 7, 14, or 28.',
    LIST_ITEM_11:
      'If the ReturnToDDCDefault field is filled with YES, then the columns of days of the week and minimum order values and fee should be left blank.',
  },
  RuleUploadGuidelines: {
    LIST_TITLE: 'Rules for a successful upload:',
    LIST_ITEM_1: 'Semicolons are the column separators',
    LIST_ITEM_2: 'Dot is decimal separator',
    LIST_ITEM_3: 'Maximum file size: 50MB',
    LIST_ITEM_4: "Do not remove any columns or change the order of the model file's columns.",
    LIST_ITEM_5: 'When saving the file, select the "Comma Separated Values (CSV)" option',
    LIST_ITEM_6: 'Date format: dd/mm/yyyy',
    LIST_ITEM_7: 'Hour format: hh:mm',
    LIST_ITEM_8:
      'We recommend formatting the customer ID columns as text so that Excel does not remove leading zeros',
  },
  ClientEdit: {
    TITLE: 'Edit Clients',
    BREADCRUMB: 'Edit Clients',
    SUBMIT_BUTTON: 'Confirm Parameters',
    SUBTITLE: 'Editing Delivery Windows',
    FIELD_EXCEPTION_LABEL: 'Type',
    FIELD_EXCEPTION_VALUE_EXCEPTION: 'Exception',
    FIELD_EXCEPTION_VALUE_DDC_DEFAULT: 'DDC Default',
    FIELD_MINVALUE_LABEL: 'Minimum Order',
    FIELD_ADDAMMOUNT_LABEL: 'Fee',
    FIELD_HAS_DELIVERY_LABEL: 'Has Delivery',
    FIELD_DELIVERY_TYPE_LABEL: 'Delivery Type',
    FIELD_DELIVERY_TYPE_VALUE_EMPTY: 'No Delivery',
    FIELD_DELIVERY_TYPE_VALUE_FLEX: 'Flex',
    FIELD_DELIVERY_TYPE_VALUE_FREE: 'Free',
    FIELD_MONEY_ERROR: 'Value should be higher than zero',
    FIELD_DELIVERY_FREQUENCY_LABEL: 'Delivery frequency',
    FIELD_DELIVERY_FREQUENCY_7: 'Every week',
    FIELD_DELIVERY_FREQUENCY_14: 'Every 2 weeks',
    FIELD_DELIVERY_FREQUENCY_28: 'Every 4 weeks',
    CLIENTS_LIST_TITLE: 'Selected Clients',
    CLIENTS_LIST_VIEW_MORE: 'View More',
    CLIENTS_LIST_HIDE: 'Hide',
    CLICK_AND_COLLECT_ENABLED: 'Click & Collect Enabled',
    VISIT_DATE: 'Visit day:',
    NO_VISIT_DATE: 'No visit day',
    BASELINE_DATE: 'Baseline date:',
    BASELINE_DATE_TOOLTIP:
      'Baseline date is used as a reference for calculating biweekly and monthly frequency delivery windows',
    BASELINE_DATE_FORMAT: 'MM/dd/yyyy',
  },
  ClientEditModalConfirm: {
    TITLE: 'Confirm changes on Delivery windows for these Clients?',
    CONFIRM_BUTTON: 'Confirm',
    CANCEL_BUTTON: 'Cancel',
    MAIN_TEXT: 'When saving, delivery windows will be recalculated. Commit the changes?',
  },
  ClientPatchModalConfirm: {
    TITLE: 'Confirm changes on Click & Collect for these Clients?',
    CONFIRM_BUTTON: 'Confirm',
    CANCEL_BUTTON: 'Cancel',
    MAIN_TEXT: 'When saving, the Click & Collect changfes will be saved. Commit the changes?',
  },
  ClientEditResponseMessage: {
    SUCCESS: 'Clients successfully updated',
    NOT_FOUND_CLIENTS: 'Clients not found:',
    DUPLICATED_CLIENTS: 'Duplicated Clients:',
    INVALID_MINVALUE_OR_ADDAMOUNT_CLIENTS: 'Invalid Minimum Order and/or Fee Values',
    DEFAULT_ERROR: 'Error saving data',
  },
  ClientRules: {
    TITLE: 'Edit Inclusion/Exclusion Rules',
    BREADCRUMB: 'Edit Inclusion/Exclusion Rules',
    OWNER_LIST_TITLE: 'Selected Clients',
    OWNER_LIST_VIEW_MORE: 'View More',
    OWNER_LIST_HIDE: 'Hide',
    SUBMIT_BUTTON: 'Confirm Parameters',
  },
  RulesModalConfirm: {
    TITLE: 'Confirm Inclusion/Exclusion rule changes for these clients?',
    CONFIRM_BUTTON: 'Confirm',
    CANCEL_BUTTON: 'Cancel',
    MAIN_TEXT:
      'This action cannot be undone, and holidays will apply to delivery windows. Commit the changes?',
  },
  RulesForm: {
    STATUS_EDITING: 'Edited Rule',
    STATUS_DELETING: 'Rule marked for exclusion',
    STATUS_INSERTING: 'New Rule',
    STATUS_PRISTINE: 'Saved Rule',
    TABLE_CELL_STATUS: 'Status',
    TABLE_CELL_NAME: 'Name',
    TABLE_CELL_TYPE: 'Unit Clients',
    TABLE_CELL_RULE_TYPE: 'Exeption Type',
    TABLE_CELL_DATE: 'Date',
    TABLE_CELL_EXPIRATION: 'Expiration Date',
    TABLE_CELL_FLEXIBLE: 'Window Type',
    TABLE_CELL_MINVALUE: 'Minimum Order',
    TABLE_CELL_ADDAMOUNT: 'Fee',
    TABLE_CELL_ACTIONS: 'Actions',
    TABLE_NO_DATA: 'No rules found with these filters',
    RULE_TYPE_INCLUSION: 'Inclusion',
    RULE_TYPE_EXCLUSION: 'Exclusion',
    DELETE_LABEL: 'Mark for delete',
    DELETE_LABEL_UNDO: 'Unmark for delete',
    DELETE_LABEL_NEW: 'Remove new',
    INVALID_DATE_MESSAGE: 'Invalid Date',
    FIELD_DATE_FORMAT: 'MM/dd/yyyy',
    FIELD_EXPIRATION_FORMAT: 'MM/dd/yyyy HH:mm',
    FIELD_EXPIRATION_EXPIRED: 'The selected date is already expired',
    FIELD_EXPIRATION_DDC: 'Using DDC default expiration',
    FIELD_DELIVERY_TYPE_VALUE_FLEX: 'Flex',
    FIELD_DELIVERY_TYPE_VALUE_FREE: 'Free',
    FIELD_TYPE_ALL_DDC_CLIENTS: 'All',
    FIELD_TYPE_ALL_DDC_FLEXIBLE_CLIENTS: 'Flex Only',
    FIELD_TYPE_ALL_DDC_FREE_CLIENTS: 'Free Only',
    FIELD_TYPE_ALL_DDC_CLIENTES_WITHOUT_DELIVERY: 'Without Delivery',
    ADD_NEW_TITLE: 'Add Inclusion/Exclusion Rule',
    ADD_NEW_FIELD_ddc: 'Units',
    ADD_NEW_FIELD_ddc_ALL: 'Select All Units',
    ADD_NEW_FIELD_client: 'Clients',
    ADD_NEW_FIELD_client_ALL: 'Select All Clients',
    ADD_NEW_DATE_FORMAT: 'MM/dd/yyyy',
    ADD_NEW_FIELD_DATE: 'Date',
    ADD_NEW_SUBMIT: 'Add',
    FILTER_TITLE: 'Search:',
    FILTER_DATE_FORMAT: 'MM/dd/yyyy',
    FILTER_START_DATE: 'Start Date',
    FILTER_END_DATE: 'End Date',
    FILTER_SUBMIT: 'Search',
    UNABLE_TO_LOAD_LIST: 'Error fetching rules list',
    UNABLE_TO_SAVE_RULES: 'Error saving rules list',
    SAVING_ERROR_WARNING_1:
      'One or more rules could not be saved. See details by hovering over the “',
    SAVING_ERROR_WARNING_2: '” icon on the highlighted lines',
    SAVING_ERROR_INVALIDTIMEZONE: 'Unit without Time Zone',
    SAVING_ERROR_LOCALEXPIRATIONDATE: 'Invalid Expiration Date',
    SAVING_ERROR_FLEXIBLE: 'Invalid Window Type',
    SAVING_ERROR_ALREADYEXISTS: 'Rule Already exists',
    SAVING_ERROR_CLIENTNOTFOUND: 'Client not found',
    SAVING_ERROR_DDCNOTFOUND: 'Unit not found',
    SAVING_ERROR_RULENOTFOUND: 'Rule not found',
    SAVING_ERROR_DUPLICATEDPARAMS: 'Duplicated Rule',
    SAVING_ERROR_EARLIERDATE: 'Rule is earlier than today',
    SAVING_ERROR_DAYOFWEEKERROR: 'There are no Minimum Order or Fee values ​​set for this day',
    ADD_ERROR_DUPLICATED: 'One or more rules were not added because they are duplicates',
    ADD_ERROR_VALIDATIONFAILED: 'Error validating fields',
  },
  ComplexFilter: {
    BUTTON_CLEAR_ALL: 'Clear all',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_APPLY: 'Apply',
    BUTTON_OPEN_FILTER: 'Filter by',
    DDCS_TITLE: 'DCs',
    DDCS_SEARCH_PLACEHOLDER: 'Search by name or DC code',
    AUTOBLOCK_SITUATION_TITLE: 'Assessment',
    [`AUTOBLOCK_SITUATION_${AutoblockSituationFilterOptions.allGood}`]: 'Normal',
    [`AUTOBLOCK_SITUATION_${AutoblockSituationFilterOptions.warning}`]: 'Needs attention',
    [`AUTOBLOCK_SITUATION_${AutoblockSituationFilterOptions.blocked}`]: 'Blocked',
    AUTOBLOCK_CAPACITY_REACHED_TITLE: 'Capacity reached (%)',
    AUTOBLOCK_CAPACITY_REACHED_MIN: 'Minimum',
    AUTOBLOCK_CAPACITY_REACHED_MAX: 'Maximum',
    PERIOD_TITLE: 'Period',
    PERIOD_START: 'Start date',
    PERIOD_END: 'End date',
    PERIOD_TAG: 'From {start} until {end}',
    PERIOD_DATEFORMAT: 'MMM dd, yyyy',
    IMPORTS_EXPORTS_STATUS_TITLE: 'Status',
  },
  Autoblock: {
    STRATEGIES: 'Strategies',
    CAPACITY_IN: 'Capacity in {value}%',
    CAPACITY_OVER: 'Capacity over {value}%',
    SUBGROUP: 'PoC subgroup: {value}',
    INCREASED_BY: 'Increased by {value}%',
    [`STRATEGY_TYPE_${StrategyType.Block}`]: 'Block delivery window',
    [`STRATEGY_TYPE_${StrategyType.IncreaseAddAmount}`]: 'Increase fee',
    [`STRATEGY_TYPE_${StrategyType.IncreaseMinValue}`]: 'Increase MOV',
    [`STRATEGY_TYPE_${StrategyType.TransformToFlex}`]: 'Change delivery to flex',
    [`STRATEGY_TYPE_${StrategyType.TransformToFree}`]: 'Change delivery to free',
    [`STRATEGY_SUBGROUP_${DdcGroup.All}`]: 'All',
    [`STRATEGY_SUBGROUP_${DdcGroup.Flex}`]: 'Flex only',
    [`STRATEGY_SUBGROUP_${DdcGroup.Free}`]: 'Free only',
    [`STRATEGY_SUBGROUP_${DdcGroup.NoDelivery}`]: 'Without Delivery',
  },
  AutoblockProgress: {
    TABTITLE: 'Strategy progress',
    BREADCRUMB: 'Delivery Capacity Availability Parameters',
    TITLE: 'Delivery Capacity Availability Parameters',
    DATAGRID_COLUMN_DDC_NAME: 'Distribution center',
    DATAGRID_COLUMN_LAST_UPDATED: 'Last updated on',
    DATAGRID_COLUMN_STRATEGY_COUNT: 'Active strategies',
    DATAGRID_COLUMN_ASSESSMENT: 'Assessment',
    DATEFORMAT: 'MMM dd, yyyy',
    ACTIVATION_DATETIMEFORMAT: 'EEE, MMM dd, yyyy, hh:mm aa',
    LAST_UPDATED_TEXT: '{date} by {user}',
    UNABLE_TO_LOAD_DETAILS: 'Error loading details.',
    DETAILS_DATEFORMAT: 'eeee, MMM dd, yyyy',
    DETAILS_CARD_OBSERVATION_TITLE: 'Capacity',
    DETAILS_CARD_OBSERVATION: 'Only showing cards for days when the capacity reached is over 5%.',
    DETAILS_CARD_CREATED_BY: 'Created by',
    DETAILS_CARD_CAPACITY_REACHED: '{visits} of {visitLimit} visits reached',
    DETAILS_ACTIVE_STRATEGY: 'Active strategy',
    DETAILS_VIEW_DETAILS: 'View details',
    DETAILS_CARD_LOAD_MORE: 'Load more',
    DETAILS_PLANNED_STRATEGIES: 'Planned strategies',
  },
  AutoblockCreate: {
    TITLE: 'Availability parameter',
    BREADCRUMB: 'Availability parameter',
    DDCS_TITLE: 'Distribution centers',
    DDCS_TEXT: 'Select the distribution centers where this availability parameter will be applied.',
    PERIOD_TITLE: 'Period',
    PERIOD_TEXT:
      'You can select one or multiple days of the week, or define a specific date to apply the availability parameter.',
    RECURRENCE_LABEL: 'Recurrence',
    RADIO_DAYS_OF_WEEK_LABEL: 'Day(s) of the week',
    RADIO_SPECIFIC_DATE_LABEL: 'Specific date',
    SPECIFIC_DATE_LABEL: 'Date',
    DAYS_OF_WEEK_LABEL: 'Day(s) of the week',
    SPECIFIC_DATE_FORMAT: 'MM/dd/yyyy',
    LIMIT_TITLE: "DC's visit limit number",
    LIMIT_TEXT: 'If the limit is reached, the delivery window will be blocked.',
    LIMIT_INPUT_PLACEHOLDER: 'Enter limit number',
    CHANNELS_LABEL: 'Order channels',
    CHANNELS_PLACEHOLDER: 'Select one or more channels',
    CHANNELS_TEXT:
      'You can select one or more order channels that will be considered in the capacity consumption calculation.',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesCustomerApp}`]: 'BEES Customer App',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesCustomerWeb}`]: 'BEES Customer Web',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesForce}`]: 'BEES Force',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesGrow}`]: 'BEES Grow',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesLink}`]: 'BEES Link',
    [`CHANNELS_OPTION_${AutoblockChannels.NonBees}`]: 'Non BEES Orders',
    [`CHANNELS_OPTION_${AutoblockChannels.Other}`]: 'Other channels',
    STRATEGIES_TITLE: 'Strategies',
    STRATEGIES_TEXT: "Define strategies to be implemented according to the DC's capacity.",
    STRATEGIES_DC_CAPACITY_LABEL: "DC's capacity (%)",
    STRATEGIES_TYPE_LABEL: 'Strategy',
    STRATEGIES_SUBGROUP_LABEL: 'Apply to PoCs',
    STRATEGIES_VALUE_INCREASE_LABEL: 'Increase amount (%)',
    STRATEGIES_ADD_BUTTON: 'Add strategy',
    LEAVE_MODAL_TITLE: 'Abandon changes?',
    LEAVE_MODAL_TEXT: 'You will lose all the information provided.',
    LEAVE_MODAL_CONFIRM: 'Yes, cancel',
    LEAVE_MODAL_CANCEL: 'No, go back',
    CONFIRM_BUTTON: 'Save',
    CONFIRM_MODAL_TITLE: 'Save availability parameter?',
    CONFIRM_MODAL_TEXT:
      "The strategies will be implemented according to the DC's capacity, in the following sequence.",
    TOAST_SUCCESS: 'Availability parameters successfully saved',
    TOAST_FAILURE: 'Error saving Availability parameters. Check the details below.',
    TOAST_MIXED: 'One or more Availability parameters could not be saved. Check the details below.',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.validationFailed}`]:
      'DC {ddcCode}: an error occurred while saving the capacity availability parameter',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.notFound}`]:
      'DC {ddcCode}: capacity availability parameter not found',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.earlierDate}`]:
      'DC {ddcCode}: it is not possible to register a capacity availability parameter for a date earlier than today',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.alreadyExists}`]:
      'DC {ddcCode}: a capacity availability parameter is already registered for this day',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.ddcNotFound}`]:
      'DC {ddcCode}: the distribution center does not exist or is not active in the Delivery Admin',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.maxStrategiesReached}`]:
      'DC {ddcCode}: a maximum of 10 strategies can be added per capacity availability parameter',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.invalidPercentage}`]:
      'DC {ddcCode}: the percentage informed in the strategy is invalid',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.duplicatedPercentage}`]:
      'DC {ddcCode}: there is more than one strategy with the same percentage',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.mandatoryIncreaseValue}`]:
      'DC {ddcCode}: it is mandatory to fill the increase percentage field for FEE or MOV increase strategies',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.invalidIncreaseValue}`]:
      'DC {ddcCode}: the percentage informed in the FEE or MOV increase strategy is invalid',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.invalidSubgroup}`]:
      'DC {ddcCode}: one or more selected subgroups in the strategies are invalid',
    SUCCESS_MESSAGE: 'DC {ddcCode}: capacity availability parameter successfully added',
    TOAST_ERROR_LOADING_AUTOBLOCK: 'Error loading the availability parameter',
  },
  errorNoVendor: {
    BREADCRUMB: 'Access denied',
    DESCRIPTION:
      "It seems that you don't have any vendorId associated with your user. Open a ticket for the support team requesting access to the vendorId you need to edit.",
    HEADER: 'Hmm... Access denied!',
  },
};

export default en;
